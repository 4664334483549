export function getDateString(date) {
  const event = new Date(date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  //  works, but breaks the immersion (since everything else is also english)
  //  var language = window.navigator.userLanguage || window.navigator.language;
  //  var result = event.toLocaleDateString(language, options);
  var result = event.toLocaleDateString("en-US", options);
  return result;
}
