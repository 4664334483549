import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false


import VueShortcuts from 'vue-shortcuts'
Vue.use(VueShortcuts, { prevent: ['input'] })

new Vue({
  render: h => h(App),
}).$mount('#app')
