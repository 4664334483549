<template>
  <div class="container">
    <VueTerminalUI
      v-shortcuts="[
        { shortcut: ['ctrl', 'l'], callback: clear, push: true, focus: true },
        { shortcut: ['tab'], callback: autocomplete, push: true, focus: true },
        { shortcut: ['ctrl', 'c'], callback: abort, push: true, focus: true },
      ]"
      ref="my-terminal-ui"
      :initMessage="['', initText, ' ']"
      :prefix="currentUser + '@' + host + ':' + currentSymbol"
      @triggerCommand="commandHandler"
    ></VueTerminalUI>
  </div>
</template>

<script>
import VueTerminalUI from "@evlad/vue-terminal-ui";
import { getDateString } from "../helper/timerUtils.js";

//used to declare filetypes of fileTree (enum)
//TODO: add hidden_folder type (for root)
const types = {
  HIDDEN: 0,
  FOLDER: 1,
  FILE: 2,
};

export default {
  components: { VueTerminalUI },

  data() {
    return {
      initText: `
 ██████╗ ███████╗████████╗██████╗  █████╗ ██╗██████╗
██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██║██╔══██╗
██║  ███╗█████╗     ██║   ██████╔╝███████║██║██║  ██║
██║   ██║██╔══╝     ██║   ██╔══██╗██╔══██║██║██║  ██║
╚██████╔╝███████╗   ██║   ██║  ██║██║  ██║██║██████╔╝
 ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═════╝
------------  2020(c) Thomas Kellner  -----------------
`,
      currentPath: "/home/guest",
      currentSymbol: "~",
      currentUser: "guest",
      host: "getraid",
      shellname: "emush",
      fileTree: [
        {
          text: "/",
          type: types.FOLDER,
          nodes: [
            {
              text: "bin/",
              type: types.FOLDER,
              nodes: [
                {
                  text: "adduser",
                  type: types.FILE,
                },
                {
                  text: "cat",
                  type: types.FILE,
                },
                {
                  text: "cd",
                  type: types.FILE,
                },
                {
                  text: "cp",
                  type: types.FILE,
                },
                {
                  text: "date",
                  type: types.FILE,
                },
                {
                  text: "less",
                  type: types.FILE,
                },
                {
                  text: "ll",
                  type: types.FILE,
                },
                {
                  text: "ls",
                  type: types.FILE,
                },
                {
                  text: "more",
                  type: types.FILE,
                },
                {
                  text: "mv",
                  type: types.FILE,
                },
                {
                  text: "mkdir",
                  type: types.FILE,
                },
                {
                  text: "ps",
                  type: types.FILE,
                },
                {
                  text: "pwd",
                  type: types.FILE,
                },
                {
                  text: "rainbow",
                  type: types.FILE,
                },
                {
                  text: "rm",
                  type: types.FILE,
                },
                {
                  text: "touch",
                  type: types.FILE,
                },
                {
                  text: "su",
                  type: types.FILE,
                },
                {
                  text: "sudo",
                  type: types.FILE,
                },
                {
                  text: "uname",
                  type: types.FILE,
                },
                {
                  text: "uptime",
                  type: types.FILE,
                },
                {
                  text: "yes",
                  type: types.FILE,
                },
              ],
            },
            {
              text: "boot/",
              type: types.FOLDER,
              nodes: [],
            },
            {
              text: "dev/",
              type: types.FOLDER,
              nodes: [],
            },
            {
              text: "etc/",
              type: types.FOLDER,
              nodes: [],
            },

            {
              text: "home/",
              type: types.FOLDER,
              nodes: [
                {
                  text: "guest/",
                  type: types.FOLDER,
                  nodes: [
                    {
                      text: "hello_world.txt",
                      content: "Hi there, this is a simple hello world file :)",
                      type: 2,
                    },
                    {
                      text: "hello_world2.txt",
                      content: "Why are we still here...just to suffer?",
                      type: 2,
                    },
                  ],
                },
              ],
            },
            {
              text: "root/",
              type: types.FOLDER,
              nodes: [],
            },
          ],
        },
      ],
      // used to interrupt repeated actions (see 'yes' )
      abortFlag: false,
      // used for uptime
      startTime: null,
    };
  },

  mounted() {
    //used for uptime
    this.startTime = new Date();
  },

  methods: {
    // detects commands and starts them
    commandHandler(command, args) {
      switch (command) {
        case "adduser":
          this.unimplementedCmd();
          break;
        case "cat":
          this.cat(args);
          break;
        case "cd":
          this.cd(args);
          break;
        case "cp":
          this.unimplementedCmd();
          break;
        case "clear":
          this.clear();
          break;
        case "date":
          this.date();
          break;
        case "echo":
          this.echo(args);
          break;
        case "less":
          this.less(args[0]);
          break;
        case "ll":
          this.ls(args[0]);
          break;
        case "ls":
          this.ls(args[0]);
          break;
        case "more":
          this.less(args[0]);
          break;
        case "mv":
          this.unimplementedCmd();
          break;
        case "mkdir":
          this.unimplementedCmd();
          break;
        case "ps":
          this.ps();
          break;
        case "pwd":
          this.pwd();
          break;
        case "rainbow":
          this.rainbow(args);
          break;
        case "rm":
          this.unimplementedCmd();
          break;
        case "su":
          this.su(args[0]);
          break;
        case "sudo":
          this.sudo();
          break;
        case "touch":
          this.touch(args[0]);
          break;
        case "uname":
          this.uname();
          break;
        case "uptime":
          this.uptime();
          break;
        case "yes":
          this.yes(args);
          break;
        default:
          this.unknownCmd(command);
          break;
      }
    },

    rainbow(args) {
      if (args.length > 0)
        this.printC(("\\color:rainbow;" + args.join(" ")).replace(/"/g, ""));
    },
    //TODO: Unfinished!!
    su(user) {
      var wantedUser = user;
      if (this.currentUser == wantedUser) return;
      if (!user) wantedUser = "root";
      this.currentUser = wantedUser;
    },

    //will maybe be implemented later, who knows...
    sudo() {
      this.printC(
        "Username is not in the sudoers file. This incident will be reported"
      );
    },
    less(path) {
      if (path) {
        this.clear();
        var catOut = this.cat([path]);
        var mv = catOut.split("\n").length;
        console.log(catOut.split("\n").length);
        for (let i = 0; i < 20 - mv; i++) {
          this.printC("~");
        }
      }
    },

    echo(args) {
      var outstring2 = args.join(" ");
      outstring2 = outstring2.replace(/"/g, "");
      this.printC(outstring2);
    },

    //prints User Agent String
    uname() {
      this.printC(navigator.appVersion);
    },

    //TODO: kinda redundant
    cat(args) {
      var combinedOutput = "";

      args.forEach((element) => {
        if (element[0] == "/") {
          var obj = this.filenameAndTreeFromPath(element);
          this.cd([obj["newPath"]]);

          var tree = this.getTree(obj["newPath"]);

          var x = tree.find((e) => e.text == obj["filename"] && e.content);
          if (x) {
            this.printC(x.content);
            combinedOutput += x.content + "\n";
            this.cd([obj.oldPath]);
          } else {
            this.printC(
              "-" + this.shellname + ": " + "cat" + ": File does not exist"
            );
          }
        } else {
          var t2 = this.getTree(this.currentPath);

          var y = t2.find((e) => e.text == element && e.content);
          if (y) {
            this.printC(y.content);
            combinedOutput += y.content + "\n";
          } else {
            this.printC(
              "-" + this.shellname + ": " + "cat" + ": File does not exist"
            );
          }
        }
      });
      return combinedOutput;
    },

    pwd() {
      this.printC(this.currentPath);
    },

    // TODO: could be optimized
    // TODO: don't use args as array
    // change directory into given path (args[0])
    cd(args) {
      if (args.length > 1) {
        this.printC("-" + this.shellname + ": cd: too many arguments");
      } else if (args[0]) {
        var tmp = this.currentPath;

        var verif = args[0];
        // if  '..' is being used
        if (args[0].charAt(0) == "." && args[0].charAt(1) == ".") {
          if (this.currentPath == "/") {
            verif = this.currentPath;
          } else {
            var split = this.currentPath.split("/");

            if (split.length == 3 && split[2] == "") {
              verif = "/";
            } else {
              split.pop();
              var np = "";
              split.forEach((element) => {
                np += element + "/";
              });
              verif = np;
            }
          }
        }

        // if  '.' is being used
        else if (verif.charAt(0) == ".") {
          if (verif.charAt(1) == "/") {
            verif = tmp + verif.replace("./", "");
          } else if (verif.charAt(1) != "/") {
            this.printC(
              "-" + this.shellname + ": cd: " + verif + ": Not a directory"
            );
          } else {
            return;
          }
        }
        // if  folder without '/' is being used
        else if (!(args[0].charAt(0) == "/")) {
          if (this.currentPath == "/") {
            verif = this.currentPath + args[0];
          } else {
            verif = this.currentPath + "/" + args[0];
          }
        }

        //filter if two '/' are after another
        var sanitizedP = this.sanitizeDoubleSlash(verif);
        this.currentPath = sanitizedP;
        var lsOutp = this.validPath(this.currentPath);
        if (!lsOutp) {
          this.printC(
            "-" + this.shellname + ": cd: " + verif + ": Not a directory"
          );
          this.currentPath = tmp;
        } else {
          if (this.currentPath == "/home/" + this.currentUser) {
            this.currentSymbol = "~";
          } else {
            this.currentSymbol = this.currentPath;
          }
        }
      } else {
        this.currentSymbol = "~";
        this.currentPath = "/home/" + this.currentUser;
      }
    },

    // new optimised list function. Lists all files / folders on given path
    ls(path) {
      // TODO: catch if no output / path wrong
      if (!path) path = this.currentPath;
      var currentTree = this.getTree(path);

      console.log(currentTree);

      currentTree.forEach((element) => {
        this.displayFile(element);
      });
    },
    validPath(path) {
      var currentTree = this.getTree(path);
      return currentTree ? true : false;
    },
    displayFile(element) {
      switch (element.type) {
        case 0:
          this.printC("\\color:#999999;" + element.text);
          break;
        case 1:
          this.printC("\\color:#23afe6;" + element.text);
          break;
        case 2:
          this.printC("\\color:#dddddd;" + element.text);
          break;
        default:
          this.printC("\\color:#ffffff;" + element.text);
          break;
      }
    },

    // returns current filetree on given path
    getTree(path) {
      // TODO: validate path (like '..' or '.') here

      //removes all slashes from path
      var pathSplit = path.split("/");
      pathSplit = pathSplit.filter((e) => e != "");

      // adds slashes for getTreeRec
      var pathSplitWithSlash = [];
      pathSplit.forEach((element) => {
        pathSplitWithSlash.push(element + "/");
      });

      // adds first element slash
      pathSplitWithSlash.unshift("/");

      return this.getTreeRec(pathSplitWithSlash, this.fileTree);
    },

    // goes through folders recursivly and if pathArr is processed(chunked away as path gets shorter), return filetree
    getTreeRec(pathArr, tree) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].type == types.FOLDER) {
          if (pathArr[0] == tree[i].text) {
            // this.displayFile(tree[i]);
            pathArr.shift();
            if (pathArr.length == 0) {
              return tree[i].nodes;
            }
            return this.getTreeRec(pathArr, tree[i].nodes);
          }
        }
      }
    },
    //clears the terminal
    clear() {
      this.$refs["my-terminal-ui"].$emit("clearHistory");
    },
    // default case, if command not found
    unknownCmd(cmd) {
      this.printC("-" + this.shellname + ": " + cmd + ": command not found");
    },

    // shows uptime since launch
    uptime() {
      var dnow = new Date();
      var diff = Math.abs(dnow - this.startTime);
      const event = new Date(diff);
      // could be prettier, but whatever
      var result = event.toUTCString().split(" ")[4];
      this.printC(result);
    },

    //TODO: needs rework
    //does something upon tab
    autocomplete() {
      var currentInput = this.$refs["my-terminal-ui"].input;
      var cmd = currentInput.split(" ")[0];
      currentInput = currentInput.split(" ")[1];
      this.fileTree[0].nodes.forEach((element) => {
        if (element.text.includes(currentInput)) {
          this.$refs["my-terminal-ui"].input = cmd + " " + element.text;
          return;
        }
      });
    },

    abort() {
      this.abortFlag = true;
      // TODO: replace hardcoded user with data obj
      this.printC(
        "\\color:#8ae234;" +
          this.currentUser +
          "@" +
          this.host +
          ":" +
          this.currentSymbol +
          " \\color:#ffffff;" +
          this.$refs["my-terminal-ui"].input +
          "^C"
      );
      this.$refs["my-terminal-ui"].input = "";
    },

    //prints 'arg' to the terminal
    printC(arg) {
      this.$refs["my-terminal-ui"].$emit("write", arg);
    },

    yes(args) {
      if (args.length == 0) args[0] = "y";

      this.abortFlag = false;
      var outstring = "";
      args.forEach((element) => {
        outstring += element.toString().replace(/"/g, "");
        outstring += " ";
      });

      var intervalid = setInterval(() => {
        this.yesRepeat(outstring, intervalid);
      }, 50);
    },
    yesRepeat(outstring, intervalid) {
      if (!this.abortFlag) this.printC(outstring);
      else {
        this.abortFlag = false;
        clearInterval(intervalid);
      }
    },

    date() {
      this.printC(getDateString(Date.now()));
    },

    unimplementedCmd() {
      this.printC("Not implemented yet...");
    },

    filenameAndTreeFromPath(path) {
      var indexPath = path.lastIndexOf("/");
      var tPath = path.substring(0, indexPath);
      var tmpPath = this.currentPath;
      var fname = path.substring(indexPath, path.length).replace("/", "");

      var obj = {
        newPath: tPath,
        filename: fname,
        oldPath: tmpPath,
      };

      return obj;
    },

    touch(path) {
      if (path[0] == "/") {
        var t = this.filenameAndTreeFromPath(path);
        this.cd([t["newPath"]]);
        this.getTree(t["newPath"]).push({
          text: t["filename"],
          type: types.FILE,
        });
        this.cd([t["oldPath"]]);
      } else if (path[0] != "/" && path.includes("/")) {
        this.printC(
          "-" +
            this.shellname +
            ": " +
            "touch" +
            ": Can't create folder. Use mkdir instead"
        );
      } else {
        this.getTree(this.currentPath).push({
          text: path,
          type: types.FILE,
        });
      }
    },

    // shows fake processes
    ps() {
      this.printC(
        "USER       PID %CPU %MEM    VSZ   RSS TTY      STAT START   TIME COMMAND"
      );
      this.printC(
        this.currentUser +
          "     7  3.5  0.0  16792  3432 tty1     S    15:08   0:00 -" +
          this.shellname
      );
      this.printC(
        this.currentUser +
          "   20  0.0  0.0  17648  2048 tty1     R    15:08   0:00 ps -aux"
      );
    },

    // used in this.cd() to sanitize paths like this: '/home//guest/' to '/home/guest/'
    // I know, could be done better... but hey it works :)
    sanitizeDoubleSlash(path) {
      var lastC = false;
      var sanitizedP = "";

      path.split("").forEach((element) => {
        if (lastC && element == "/") {
          element = "";
          lastC = false;
        }
        lastC = element == "/";
        sanitizedP += element;
      });
      return sanitizedP;
    },
  },
};
</script>

<style>
.prefix {
  color: #8ae234;
}
</style>
