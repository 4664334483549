<template>
  <div id="app">
    <console   ></console>
  </div>
</template>

<script>
import console from './components/console.vue'

export default {
  name: 'App',
  components: {
    console
  }
}
</script>

<style>
</style>
